@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: Amelaryas;
  src: url("fonts/amelaryas.otf") format("opentype"),
       url("fonts/Amelaryas.ttf") format("truetype");
}

@font-face {
  font-family: Leky;
  src: url("fonts/leky.otf") format("opentype");
}

@font-face {
  font-family: LekyCalgria;
  src: url("fonts/LekyCalgria.ttf") format("truetype");
}

@font-face {
  font-family: Hand;
  src: url("fonts/hand.otf") format("opentype");
}


:root {
  --primary-clr: #035824;
}

body {
  margin: 0;
  font-family: Amelaryas;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
}


section {
  width: min(100%, 100vw);
  height: auto;
}

.container {
  width: min(1600px, 95%);
  padding: 0 30px;
}

.hero-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 0 90px 0;
  color: black;
}

.hero-header {
  display: flex;
  flex-direction: column;
}

h1 {
  margin: 0;
  font-size: 100px;
  line-height: 150px;
  text-align: left;
  margin: 30px 0 50px 0;
}

p {
  font-family: 'Poppins', sans-serif;
  font-weight: medium;
  max-width: 70ch;
  font-size: 20px;
}

.highlight {
  color: var(--primary-clr);
}

#heroSection {
  background-color:#ff99ce;
  background-image:
  radial-gradient(at 65% 2%, hsla(124,70%,65%,1) 0px, transparent 50%),
  radial-gradient(at 53% 13%, hsla(130,65%,78%,1) 0px, transparent 50%),
  radial-gradient(at 2% 62%, hsla(39,84%,74%,1) 0px, transparent 50%),
  radial-gradient(at 95% 15%, hsla(174,60%,67%,1) 0px, transparent 50%),
  radial-gradient(at 72% 62%, hsla(193,95%,65%,1) 0px, transparent 50%),
  radial-gradient(at 79% 76%, hsla(308,86%,77%,1) 0px, transparent 50%),
  radial-gradient(at 63% 50%, hsla(5,89%,68%,1) 0px, transparent 50%);
}

.products-container {
  display: flex;
  padding: 20px 0;
}

.artwork-card {
  width: 400px;
  height: 300px;
  /* box-shadow: 2px 2px 10px 0.1px rgba(0, 0, 0, 0.2); */
  background-color: rgba(234, 234, 234, 0.28);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.artwork-card .top {
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.artwork-card-content-top h1 {
  font-size: 60px;
}
